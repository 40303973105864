<template>
    <section class="height-full common-inset-shadow-dark relative" style="padding-bottom:1rem">
        <div class="control-box" v-if="basic.chart">
            <section class="flex-ver-center height-full">
                <el-switch style="--el-switch-on-color: #13ce66; --el-switch-off-color: #dadada" v-model="basic.openLinePoint" @change="(val) => fn.seeLinePoint(val)"></el-switch>
                <span class="color-white" style="padding-left:3px;">显示数据点</span>
            </section>
        </div>
        <div id="power-line-chart" class="height-full relative" ref="box"></div>
    </section>
</template>
<script setup>
import { useWebsocketStore } from '~/store/socket';
import {
    dataZoomHandle,
    xAxisHandle,
    setLinearOptions,
    gridHandle,
    legendHandle,
    lineHandle,
    yAxisHandle,
    tooltipHandle,
} from '~/utils/tools/echart-config';
import helper from '~/utils/tools/helper';

const socketStore = useWebsocketStore();
const props = defineProps({
    data: Array,
})
const { data } = toRefs(props);

const box = ref(null);
const basic = reactive({
    chart: null,
    openLinePoint: false,
});
let tooltipCount = 0;
const fn = {
    seeLinePoint(val) {
        if (basic.chart) {
            const options = basic.chart.getOption();
            let series = options.series;
            if (!Array.isArray(series)) {
                series = [series];
            }
            const obj = {
                series: series.map(item => {
                    return {
                        ...item,
                        symbol: val ? 'circle' : 'none',
                        symbolSize: 4,
                    }
                }),
            }
            basic.chart.setOption(obj);
        }
    },
    createChart(data = []) {
        const xAxis = [];
        const light = [];
        const actual = [];
        const theoretical = [];
        data.forEach(item => {
            xAxis.push(item.timestamp);
            light.push(item.lightingIntensity);
            actual.push(item.actualPower);
            theoretical.push(item.theoreticalPower);
        })
        const options = {
            tooltip: tooltipHandle({
                options: {
                    valueFormatter(value) {
                        tooltipCount++;
                        if (tooltipCount == 3) {
                            tooltipCount = 0;
                            return value ? value + 'W/㎡' : '----';
                        } else {
                            return value ? value + 'kW' : '----';
                        }
                    }
                }
            }),
            xAxis: xAxisHandle({
                data: xAxis,
            }),
            dataZoom: dataZoomHandle(),
            yAxis: [
                yAxisHandle({
                    name: '功率',
                    axisLabelUnit: 'kW'
                }),
                yAxisHandle({
                    name: '强度',
                    axisLabelUnit: 'W/㎡',
                    options: {
                        nameTextStyle: {
                            padding: [0, -10, 0, 0],
                            color: '#efefef',
                        },
                    }
                }),
            ],
            legend: legendHandle({
                options: {
                    icon: 'circle',
                }
            }),
            series: [
                lineHandle({
                    name: '实际功率',
                    data: actual,
                    options: {
                        yAxisIndex: 0,
                        dataKey: 'actualPower',
                        connectNulls: true,
                    }
                }),
                lineHandle({
                    name: '理论功率',
                    data: theoretical,
                    options: {
                        yAxisIndex: 0,
                        itemStyle: {
                            color: "orange",
                        },
                        dataKey: 'theoreticalPower',
                        connectNulls: true,
                    }
                }),
                lineHandle({
                    name: '光照强度',
                    data: light,
                    options: {
                        connectNulls: true,
                        dataKey: 'lightingIntensity',
                        yAxisIndex: 1,
                        itemStyle: {
                            color: "#ffff00",
                        },
                        lineStyle: {
                            width: 1,
                        },
                        areaStyle: {
                            color: setLinearOptions('light'),
                        }
                    }
                }),
            ],
            grid: gridHandle({
                top: '22%',
            }),
            title: {
                show: false,
            }
        };
        basic.chart.setOption(options)
    },
    async init(info) {
        basic.chart = markRaw(echarts.init(box.value));
        if (info) {
            fn.createChart(info);
        }
    },
    updateChartData(info) {
        if (basic.chart) {
            const options = basic.chart.getOption();
            const updateXaxisData = (list) => {
                return list.map(item => {
                    return {
                        ...item,
                        data: item.data.concat(info.map(i => i.timestamp)),
                    }
                });
            }
            const updateSeriesData = (series) => {
                return series.map((item, index) => {
                    return {
                        ...item,
                        data: item.data.concat(info.map(i => i[item.dataKey])),
                    }
                })
            }
            const opts = {
                animation: false,
                xAxis: updateXaxisData(options.xAxis),
                series: updateSeriesData(options.series),
            };
            basic.chart.setOption(opts);
        }
    },
    destroyChart() {
        basic.chart && basic.chart.dispose();
    }
}
watch(data, val => {
    fn.destroyChart();
    fn.init(val);
}, {
    deep: true,
});
watch(() => socketStore.screenChartData, val => {
    fn.updateChartData(val);
}, {
    deep: true,
});

const debounceHandle = helper.debounce(() => {
    basic.chart && basic.chart.resize();
}, 200);
onMounted(() => {
    if (data.value?.length > 0) {
        fn.init(data.value)
    }
    window.addEventListener('resize', debounceHandle);
})
onUnmounted(() => {
    fn.destroyChart();
    window.removeEventListener('resize', debounceHandle);
})
</script>
<style lang="scss" scoped>
.common-inset-shadow-dark {
    padding: 20px;
}

.control-box {
    position: absolute;
    left: 1rem;
    top: 0.2rem;
    z-index: 10;
}
</style>