<template>
    <section class="output-gauge">
        <section class="common-inset-shadow-dark height-full">
            <div id="output-guage-chart" class="height-full relative"></div>
        </section>
    </section>
</template>
<script setup>

const props = defineProps({
    data: Object,
})
const { data } = toRefs(props);

const basic = reactive({
    chart: null,
})
const fn = {
    createChart(info) {
        const percent = parseFloat(info.output) / 100;
        basic.chart = new G2Plot.Liquid('output-guage-chart', {
            percent,
            outline: {
                border: 0,
                distance: 1,
            },
            wave: {
                count: 3,
                length: 128,
            },
            statistic: {
                content: {
                    formatter: () => `出力比：${info.output || ''}`,
                    style: {
                        color: 'rgba(255,255,255,0.65)',
                        fontSize: 14,
                    },
                },
            },
        });

        basic.chart.render();
    },
    async init(info) {
        info && fn.createChart(info);
    },
    destroyChart() {
        basic.chart && basic.chart.destroy();
    }
}
watch(data, val => {
    fn.destroyChart();
    fn.init(val);
}, {
    deep: true,
});
onMounted(() => {
    fn.init(data.value);
})
onUnmounted(() => {
    fn.destroyChart();
})
</script>
<style lang="scss" scoped>
.common-inset-shadow-dark {
    padding: 20px;
}
</style>