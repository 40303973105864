const obj = {
    temperature: {
        label: '当前温度',
        rank: '温度',
        svg: '温度',
        column: true,
        click: {
            chart: 'line', // 实时大屏温度弹框
            title: '温度曲线',
            date: 'date',
        }
    },
    light: {
        label: '光照',
        rank: '光照',
        svg: '光照',
        column: true,
    },
    stations: {
        label: '电站',
        rank: '电站',
        svg: '电站',
        column: true,
        statistics: {
            show: true,
            sort: 1,
        }
    },
    installed: {
        label: '总装机',
        rank: '装机量',
        svg: '装机量',
        column: true,
        chart_column: 1,
        statistics: {
            show: true,
            sort: 2,
        }
    },
    output: {
        label: '出力比',
        rank: '出力比',
        svg: '功率',
        chart_column: 2,
        column: true,
    },
    power: {
        label: '实际功率',
        rank: '实际功率',
        svg: '功率',
        rank_column: 2, // 排序位置
        chart_column: 1,
    },
    maxPower: {
        label: '最大功率',
        rank: '最大功率',
        svg: '功率',
        rank_column: 2, // 排序位置
        chart_column: 1,
    },
    daily: {
        label: '日发电量',
        rank: '日发电量',
        svg: '日发电量',
        rank_column: 1, // 排序位置
        column: false,
    },
    month: {
        label: '月发电量',
        rank: '月发电量',
        svg: '月发电量',
        rank_column: 1, // 排序位置
        column: false,
    },
    year: {
        label: '年发电量',
        rank: '年发电量',
        svg: '年发电量',
        rank_column: 1, // 排序位置
        column: false,
    },
    total: {
        label: '累计发电量',
        rank: '累计发电量',
        svg: '累计发电量',
        rank_column: 1, // 排序位置
        column: false,
    },
    co2: {
        label: 'CO2减排',
        rank: 'CO2减排',
        svg: '二氧化碳',
        rank_column: 1, // 排序位置
        column: true,
        statistics: {
            show: true,
            sort: 4,
        }
    },
    coal: {
        label: '节约标准煤',
        rank: '节约标准煤',
        svg: '煤',
        rank_column: 1, // 排序位置
        column: true,
        statistics: {
            show: true,
            sort: 5,
        }
    },
    tree: {
        label: '植树',
        rank: '树木',
        svg: '树木',
        rank_column: 1, // 排序位置
        column: true,
        statistics: {
            show: true,
            sort: 6,
        }
    },
    generation: {
        label: '发电量',
        svg: '发电量',
        rank: '发电量',
        statistics: {
            show: true,
            sort: 3,
        },
        rank_column: 3, // 统计排名
    },
    hour: {
        label: '小时数',
        rank: '小时数',
        rank_column: 3,
    }
}

export default obj;