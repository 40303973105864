const setLinearOptions = (type, options = {}) => {
    const config = {
        x: 0,
        y: 0,
        x2: 0,
        y2: 1,
        start: 0,
        end: 1,
        global: false,
        startColor: 'transparent',
        endColor: 'transparent',
    };
    if (type == 'light') {
        config.startColor = '#ffff00';
        config.endColor = 'rgb(255,255,234,0.4)';
        Object.assign(config, options);
    }
    if (type == 'energy') {
        config.startColor = '#ffff00';
        config.endColor = 'rgb(255,255,255,0.6)';
        Object.assign(config, options);
    }
    return {
        type: 'linear',
        x: config.x,
        y: config.y,
        x2: config.x2,
        y2: config.y2,
        colorStops: config.colorStops || [
            {
                offset: config.start, color: config.startColor // 0% 处的颜色
            },
            {
                offset: config.end, color: config.endColor // 100% 处的颜色
            }
        ],
        global: config.global // 缺省为 false
    }
}

const xAxisHandle = (config = {}) => {
    const { options, data } = config;
    return {
        type: 'category',
        z: 10,
        axisLabel: {
            color: "#ffffff",
            formatter(value) {
                return value + (config.axisLabelUnit || '');
            },
        },
        data,
        splitLine: {
            show: true,
            lineStyle: {
                type: 'dashed',
                color: 'rgba(255,255,255,0.1)'
            }
        },
        ...options,
    }
}
const yAxisHandle = (config = {}) => {
    const { options, name } = config;
    return {
        type: 'value',
        splitNumber: 5,
        name,
        alignTicks: true,
        min: 0,
        nameTextStyle: {
            padding: [0, 0, 0, -40],
            color: '#efefef',
        },
        axisLabel: {
            color: '#efefef',
            formatter(value) {
                return value + (config.axisLabelUnit || '');
            },
        },
        splitLine: {
            lineStyle: {
                type: 'dashed',
                color: 'rgba(255,255,255,0.1)'
            }
        },
        ...options,
    }
}
const tooltipHandle = (config = {}) => {
    const { options, unit } = config;
    return {
        confine: true,
        show: true,
        trigger: 'axis',
        valueFormatter(value) {
            return value ? value + (unit || '') : '----';
        },
        ...options,
    }
}
const legendHandle = (config = {}) => {
    const { options } = config;
    return {
        show: true,
        itemGap: 5,
        itemWidth: 20,
        textStyle: {
            fontSize: 10,
            color: 'inherit',
            padding: [0, 0, 0, -4],
        },
        ...options,
    }
}
const lineHandle = (config = {}) => {
    const { options, data, name } = config;
    return {
        name, // actualPower
        type: 'line',
        data,
        smooth: true,
        connectNulls: false,
        itemStyle: {
            color: "#12ba12",
        },
        symbol: 'none',
        ...options,
    }
}
const barHandle = (config = {}) => {
    let { options, data, name, barColor, labelUnit, labelShow } = config;
    return {
        name,
        type: 'bar',
        data,
        showBackground: true,
        label: {
            show: Boolean(labelShow),
            position: 'top',
            align: 'center',
            varticalAlign: 'bottom',
            color: 'inherit',
            formatter: val => val.value + (labelUnit || ''),
        },
        labelLayout: {
            hideOverlap: true,
        },
        itemStyle: {
            color: barColor || "#12ba12",
            shadowColor: 'rgba(0, 0, 0, 0.5)',
            shadowBlur: 10
        },
        connectNulls: false,
        ...options,
    }
}
const gridHandle = (config = {}) => {
    const { options, top, bottom, left, right } = config;
    return {
        top,
        left: left ?? '1%',
        right: right ?? '1%',
        bottom: bottom ?? 25,
        containLabel: true,
        ...options,
    }
}
const dataZoomHandle = (config = {}) => {
    const { options } = config;
    return  {
        show: true,
        type: 'slider',
        brushSelect: false,
        height: 20,
        throttle: 300,
        handleStyle: {
            borderWidth: 3,
        },
        textStyle: {
            color: '#ffffff',
        },
        bottom: 0,
        ...options
    }
    
}
export {
    setLinearOptions,
    dataZoomHandle,
    gridHandle,
    lineHandle,
    barHandle,
    xAxisHandle,
    yAxisHandle,
    legendHandle,
    tooltipHandle,
}