// 查询电站数量及总装机量
export const fetchInstallData = (data = {}) => useRequest.post('/api/common/rt-installed', data);
// 实时功率+曲线
export const fetchPowerChartData = (data = {}) => useRequest.post('/api/common/rt-power-chart', data);
// 实时气象
export const fetchWeatherData = (data = {}) => useRequest.post('/api/common/rt-weather', data);
// 日/月/年-发电量
export const fetchGenerationData = (data = {}) => useRequest.post('/api/common/rt-generation', data);
// 设备状态数量统计
export const fetchEquipmentCountData = (data = {}) => useRequest.post('/api/common/rt-equipment', data);
// 单位功率汇总列表
export const fetchInstalledDetail = (data = {}) => useRequest.post('/api/common/rt-installed-detail', data);
// 单位发电量汇总列表
export const fetchEnergyDetail = (data = {}) => useRequest.post('/api/common/rt-generation-detail', data);

// 统计-发电量，日、月、年、累计
export const fetchTotalEnergyCount = (type, data = {}) => useRequest.post(`/api/common/summary-${type}`, data);
// 统计-图表
export const fetchSummaryChart = (type, data = {}) => useRequest.post(`/api/common/summary-${type}-chart`, data);
// 统计-历史功率-图表
export const fetchHistoryPowerChartData = (data = {}) => useRequest.post('/api/common/summary-actual-power-chart', data);
// 统计-发电量-单位统计
export const fetchEnergyTotalDetail = (type, data = {}) => useRequest.post(`/api/common/summary-${type}-detail`, data);
